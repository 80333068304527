import React, { PureComponent } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { Callback, UserData } from 'react-oidc';
import { injectIntl } from 'react-intl';
import _get from 'lodash/get';
import AlertBanner from '../AlertBanner/AlertBanner';

import ErrorPage from '../ErrorPage';
import ClassPage from '../ClassPage';
import ProfilePage from '../ProfilePage/ProfilePage';
import withUserAttributes from '../withUserAttributes/withUserAttributes';
import Footer from '../Footer';
import ClassHeader from '../ClassHeader';
import withUseCode from '../withUseCode';
import { configureAuth, UserManagerContext } from '../.config/configureAuth';
import { parseURIParams, recordTimingMetrics } from '../utils';
import { AppError, AUTH_ERROR_TYPE } from '../utils/errors';
import {
    LOGOUT_KEY,
    PATH_KEY,
    LOCALE_KEY,
    SEARCH_KEY,
} from '../utils/storage-keys';

import './App.scss';
import LogoutPage from '../LogoutPage';
import environment from '../.config/environment';

class App extends PureComponent {
    static contextType = UserData;

    constructor(props) {
        super(props);

        this.window = props.depWindow || window;
        const config = environment(this.window) || {};
        this.featureFlags = config.featureFlags;

        const { generateAuthenticator, userManager } = configureAuth({
            globals: this.window,
        });

        this.generateAuthenticator = generateAuthenticator;
        this.userManager = userManager;

        const { pathname, search } = props.location;
        if (pathname.length > 1 && pathname !== '/callback') {
            // set persisting values to have after authentication
            this.window.sessionStorage.setItem(PATH_KEY, pathname);
            this.window.sessionStorage.setItem(LOCALE_KEY, props.intl.locale);
            this.window.sessionStorage.setItem(SEARCH_KEY, search);
        }
    }

    handleError = err => {
        if (
            err.message ===
                'ResizeObserver loop completed with undelivered notifications.' ||
            err.message === 'ResizeObserver loop limit exceeded'
        ) {
            err.stopImmediatePropagation && err.stopImmediatePropagation();
        } else {
            return new AppError(err.message);
        }
    };

    UNSAFE_componentWillMount() {
        // NOTE: need to allow for injecting a window object for tests because
        // jsdom overwrites mocking addEventListener inside componentWillMount
        const win = this.props.depWindow || window;
        // Collect metrics AFTER the page has loaded
        win.addEventListener('load', recordTimingMetrics);
        // Collect JS errors that bubble up to window.onerror
        win.addEventListener('error', this.handleError);
    }

    componentWillUnmount() {
        const win = this.props.depWindow || window;
        // Clean up event listeners
        win.removeEventListener('load', recordTimingMetrics);
        win.removeEventListener('error', this.handleError);
    }

    render() {
        const { generateAuthenticator, userManager } = this;
        const getAndClearFromStorage = () => {
            const val = this.window.sessionStorage.getItem(LOGOUT_KEY);
            this.window.sessionStorage.removeItem(LOGOUT_KEY);
            return val;
        };
        const logoutRenderer = () => {
            const classroomId = getAndClearFromStorage();
            return <LogoutPage {...{ classroomId }} />;
        };
        const callbackRenderer = ({ history }) => {
            const classroomId = getAndClearFromStorage();
            if (classroomId) {
                return <Redirect to={`/logout?classroomId=${classroomId}`} />;
            }

            const onSuccess = () => {
                const search =
                    this.window.sessionStorage.getItem(SEARCH_KEY) || '';
                const storedPath = this.window.sessionStorage.getItem(PATH_KEY);
                const path = storedPath ? storedPath + search : '/error';
                this.window.sessionStorage.removeItem(PATH_KEY);
                this.window.sessionStorage.removeItem(LOCALE_KEY);
                this.window.sessionStorage.removeItem(SEARCH_KEY);
                history.push(path);
            };

            const onError = error => {
                console.error(error);
                new AppError('Error getting authenticated', {
                    type: AUTH_ERROR_TYPE,
                });
                history.push('/error');
            };
            const callbackProps = {
                onSuccess,
                onError,
                userManager,
            };
            return <Callback {...callbackProps} />;
        };
        const profileRenderer = () => {
            const Authenticator = generateAuthenticator(
                withUserAttributes(ProfilePage)
            );
            return <Authenticator />;
        };
        return (
            <UserManagerContext.Provider value={this.userManager}>
                <div className="awsui">
                    <ClassHeader
                        userManager={userManager}
                        globals={this.window}
                        featureFlags={this.featureFlags}
                    />
                    <AlertBanner globals={this.window} />
                    <main className="app-content">
                        <Switch>
                            <Route path="/callback" render={callbackRenderer} />
                            <Route
                                path="/class/:id"
                                render={({ match, location: { search } }) => {
                                    const classroomId = _get(
                                        match,
                                        'params.id',
                                        ''
                                    );
                                    const { accessCode } = parseURIParams(
                                        search.substring(1)
                                    );

                                    this.window.history.pushState(
                                        this.window.history.state,
                                        '',
                                        this.window.location.pathname
                                    );

                                    const Authenticator = generateAuthenticator(
                                        withUseCode(injectIntl(ClassPage))
                                    );

                                    return (
                                        <Authenticator
                                            classroomId={classroomId}
                                            accessCode={
                                                accessCode
                                                    ? this.window.decodeURIComponent(
                                                          accessCode
                                                      )
                                                    : undefined
                                            }
                                        />
                                    );
                                }}
                            />
                            <Route
                                exact
                                path="/logout"
                                render={logoutRenderer}
                            />
                            {/*https://sim.amazon.com/issues/BKR-5569*/}
                            {this.featureFlags?.userSuppliedPII ? (
                                <Route
                                    exact
                                    path="/profile"
                                    render={profileRenderer}
                                />
                            ) : null}
                            <Route component={ErrorPage} />
                        </Switch>
                    </main>
                    <Footer />
                </div>
            </UserManagerContext.Provider>
        );
    }
}

export default withRouter(injectIntl(App));
