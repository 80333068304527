import React from 'react';
import { Badge, Box, Button, Grid } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './JamLabCard.messages';
import BaseCard from '../BaseCard/';
import { translateDurationUnit } from '../utils/helpers';
import moment from 'moment';

const {
    jamUnavailableBtnLabel,
    jamUnavailableBtnText,
    jamEventStatusLabel,
    jamEventUrlLabel,
    jamStatusPending,
    jamStatusUpdating,
    jamStatusNotStarted,
    jamStatusOngoing,
    jamStatusEnded,
    jamStatusCancelled,
    jamStatusError,
    jamCardTitle,
    jamCardDescription,
} = messages;

const JAM_EVENT_PENDING = 'PENDING';
const JAM_EVENT_PENDING_APPROVAL = 'PENDING_APPROVAL';
const JAM_EVENT_UPDATING = 'UPDATING';
const JAM_EVENT_NOT_STARTED = 'NOT_STARTED';
const JAM_EVENT_ONGOING = 'ONGOING';
const JAM_EVENT_ENDED = 'ENDED';
const JAM_EVENT_CANCELLED = 'CANCELLED';
const JAM_EVENT_ERROR = 'ERROR';

const getStatusBadgeColor = status => {
    switch (status) {
        case JAM_EVENT_ONGOING:
            return 'green';
        case JAM_EVENT_ENDED:
            return 'blue';
        case JAM_EVENT_ERROR:
            return 'red';
        default:
            return 'grey';
    }
};

const getStatusTranslationMessageBase = formatFn => status => {
    switch (status) {
        case JAM_EVENT_PENDING:
        case JAM_EVENT_PENDING_APPROVAL:
            return formatFn(jamStatusPending);
        case JAM_EVENT_UPDATING:
            return formatFn(jamStatusUpdating);
        case JAM_EVENT_NOT_STARTED:
            return formatFn(jamStatusNotStarted);
        case JAM_EVENT_ONGOING:
            return formatFn(jamStatusOngoing);
        case JAM_EVENT_ENDED:
            return formatFn(jamStatusEnded);
        case JAM_EVENT_CANCELLED:
            return formatFn(jamStatusCancelled);
        case JAM_EVENT_ERROR:
            return formatFn(jamStatusError);
        default:
            return '-';
    }
};

const JamActions = ({ eventURL, eventStatus }) => {
    const { formatMessage } = useIntl();
    const badgeMessage = getStatusTranslationMessageBase(formatMessage);
    return (
        <Grid
            gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}
            data-testid="lab-card__jam-actions"
        >
            <Box>
                <Box variant="p" padding={{ bottom: 's' }}>
                    {formatMessage(jamEventStatusLabel)}
                </Box>
                <Badge
                    color={getStatusBadgeColor(eventStatus)}
                    data-testid="lab-card__jam-actions-badge"
                >
                    {badgeMessage(eventStatus)}
                </Badge>
            </Box>
            <Box>
                <Box variant="p" padding={{ bottom: 's' }}>
                    {formatMessage(jamEventUrlLabel)}
                </Box>
                <a className="jam-event-link" href={eventURL}>
                    {eventURL}
                </a>
            </Box>
        </Grid>
    );
};

const JamLabCard = ({
    disabled,
    startsOn = 0,
    endsOn = 0,
    courseTitle,
    eventURL,
    eventStatus = '',
}) => {
    const { formatMessage, locale } = useIntl();
    const containerClasses = ['lab-card__actions'];
    if (!disabled) {
        containerClasses.push('jam-event');
    }
    const title = `${formatMessage(jamCardTitle)} - ${courseTitle}`;
    const description = formatMessage(jamCardDescription);
    const eventDurationInSeconds = endsOn - startsOn;
    const duration = translateDurationUnit(
        moment.duration(eventDurationInSeconds, 'seconds'),
        'hour',
        locale
    );
    return (
        <BaseCard
            title={title}
            duration={duration}
            description={description}
            align={disabled ? 'right' : 'left'}
        >
            {disabled ? (
                <Button
                    disabled
                    ariaLabel={formatMessage(jamUnavailableBtnLabel)}
                >
                    {formatMessage(jamUnavailableBtnText)}
                </Button>
            ) : (
                <JamActions eventURL={eventURL} eventStatus={eventStatus} />
            )}
        </BaseCard>
    );
};

export default JamLabCard;
