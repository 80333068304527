import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    modalTitle: {
        id: 'logout.modal.title',
        description: 'Top of the logout modal',
        defaultMessage: "You're signed out",
    },
    modalBody: {
        id: 'logout.modal.body',
        description: 'Main modal contents',
        defaultMessage:
            'You are no longer signed into AWS Classrooms. As a note, you are still enrolled in the course. If you would like to access the course, please try signing in again',
    },
    modalSigninBtnLabel: {
        id: 'logout.modal.signinBtn',
        description: 'Sign In Button',
        defaultMessage: 'Sign in',
    },
    modalTrainingBtnLabel: {
        id: 'logout.modal.trainingBtn',
        description: 'Label for button to go to aws.training',
        defaultMessage: 'Return to AWS.Training site',
    },
});

export default messages;
