import React from 'react';
import { Icon } from '@amzn/awsui-components-react';
import './BaseCard.scss';

const BaseCard = ({
    title,
    duration,
    description,
    align = 'left',
    children,
}) => {
    const containerClasses = `lab-card__actions ${align}`;
    return (
        <div className="lab-card">
            <div className="lab-card__header">
                <h3>{title}</h3>
                <span className="lab-card__duration">
                    <Icon
                        className="lab-card__duration-icon"
                        name="status-pending"
                        variant="subtle"
                    />{' '}
                    {duration}
                </span>
            </div>

            <p>{description}</p>
            <div className={containerClasses}>{children}</div>
        </div>
    );
};

export default BaseCard;
