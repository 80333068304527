import environment from '../.config/environment';

const generateApi = (name, baseUrl, opts = {}) => ({
    [name]: {
        baseUrl,
        ...opts,
    },
});

const { HOST_NAME: { CLASSROOM } = {} } = environment(window);

export const hostnames = {
    CLASSROOM,
};

export const apis = {
    ...generateApi('classData', `${hostnames.CLASSROOM}/GetClassroom`),
    ...generateApi('useAccessCode', `${hostnames.CLASSROOM}/UseAccessCode`, {
        loading: false,
    }),
    ...generateApi(
        'createTraining',
        `${hostnames.CLASSROOM}/CreateTrainingSession`,
        { loading: false }
    ),
    ...generateApi('getTraining', `${hostnames.CLASSROOM}/GetTraining`, {
        loading: false,
    }),
    ...generateApi(
        'getTrainingPermissions',
        `${hostnames.CLASSROOM}/GetTrainingPermissions`,
        { loading: false }
    ),
    ...generateApi('getGuides', `${hostnames.CLASSROOM}/GetGuidesLtiParams`, {
        loading: false,
    }),
    ...generateApi(
        'sendClientLog',
        `${hostnames.CLASSROOM}/SendClientLog/StudentUI`
    ),
    ...generateApi(
        'putUserAttributes',
        `${hostnames.CLASSROOM}/PutUserAttributes`,
        { loading: false }
    ),
    ...generateApi(
        'getUserAttributes',
        `${hostnames.CLASSROOM}/GetUserAttributes`,
        { loading: false }
    ),
};

export const cognitoSignoutClient = (globals = window) => {
    const { authorizer: { issuer, clientId } = {} } = environment(globals);
    const { origin } = new URL(issuer);
    const url = `${origin}/`;
    return refresh_token =>
        globals
            .fetch(url, {
                method: 'POST',
                headers: new Headers({
                    'X-Amz-Target':
                        'AWSCognitoIdentityProviderService.RevokeToken',
                    'content-type': 'application/x-amz-json-1.1',
                }),
                body: JSON.stringify({
                    ClientId: clientId,
                    Token: refresh_token,
                }),
            })
            .then(async response => {
                const result = await response.json();
                if (!response.ok) {
                    console.error(result);
                }
                return result;
            });
};
