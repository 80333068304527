import React from 'react';
import { useIntl } from 'react-intl';
import 'moment/min/locales';

import LabCard from '../LabCard';
import './LabList.scss';
import JamLabCard from '../JamLabCard';
import { translateDurationUnit } from '../utils/helpers';

const LabList = ({
    data,
    onClassClick,
    activeTrainings = new Set([]),
    loadedLabs = [],
    titleRegex,
    jamTrainings,
}) => {
    const { locale } = useIntl();

    if (!data || !data.content) return null;

    const labList = data.course.content
        .filter(v => data.content[v])
        .map((id, i) => ({
            ...data.content[id],
            id,
            disabled: !activeTrainings.has(id),
            onClick: onClassClick.bind(null, id),
        }))
        .map(({ duration, durationUnit, title, ...rest }) => ({
            ...rest,
            title: title.replace(titleRegex, ''),
            duration: translateDurationUnit(duration, durationUnit, locale),
        }));

    return (
        <ol className="lab-list awsui-util-pl-n">
            {labList.map((lab, index) => {
                const labStatus = loadedLabs.find(l => lab.id === l.arn)
                    ?.labStatus;
                return (
                    <li key={lab.id}>
                        <LabCard {...lab} labStatus={labStatus} index={index} />
                    </li>
                );
            })}
            {(jamTrainings || []).map(jam => {
                const {
                    contentId,
                    metaData,
                    fulfillmentStatus,
                    trainingId,
                } = jam;
                const props = {
                    courseTitle: data?.course?.title,
                    eventURL: metaData?.eventURL,
                    eventStatus: metaData?.eventStatus || fulfillmentStatus,
                    startsOn: metaData?.startsOn,
                    endsOn: metaData?.endsOn,
                    disabled: !activeTrainings.has(contentId),
                };
                return (
                    <li key={trainingId}>
                        <JamLabCard {...props} />
                    </li>
                );
            })}
        </ol>
    );
};

export default LabList;
