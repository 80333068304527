import React from 'react';
import { Button } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './LabCard.messages';
import BaseCard from '../BaseCard/BaseCard';

const {
    disabledLabBtnLabel,
    disabledLabBtnText,
    enabledLabBtnLabel,
    enabledLabBtnText,
} = messages;

const LaunchButton = ({ disabled, onClick, index }) => {
    const { formatMessage } = useIntl();
    const messageToRender = disabled ? disabledLabBtnLabel : enabledLabBtnLabel;
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            variant="primary"
            ariaLabel={formatMessage(messageToRender, { index: index + 1 })}
        >
            {disabled
                ? formatMessage(disabledLabBtnText)
                : formatMessage(enabledLabBtnText)}
        </Button>
    );
};

const LabCard = ({
    title,
    duration,
    description,
    disabled,
    onClick,
    index,
}) => {
    return (
        <BaseCard
            title={title}
            duration={duration}
            description={description}
            align="right"
        >
            <LaunchButton disabled={disabled} onClick={onClick} index={index} />
        </BaseCard>
    );
};

export default LabCard;
