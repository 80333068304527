import React from 'react';
import { makeAuthenticator, makeUserManager } from 'react-oidc';

import environment from '../.config/environment';

export const UserManagerContext = React.createContext(null);

export const useUserManager = () => React.useContext(UserManagerContext);

export const configureAuth = ({ globals }) => {
    const {
        authorizer: {
            clientId,
            issuer,
            authorization_endpoint,
            token_endpoint,
            end_session_endpoint,
        },
    } = environment(globals);
    const { origin } = globals.location;
    const redirect_uri = `${origin}/callback`;
    const callbackUrl = encodeURIComponent(redirect_uri);
    const logoutUrl = `${end_session_endpoint}?client_id=${clientId}&logout_uri=${callbackUrl}`;
    const beakerDevUserManagerParams = {
        client_id: clientId,
        authority: issuer,
        redirect_uri,
        response_type: 'code',
        automaticSilentRenew: true,
        ...(authorization_endpoint
            ? {
                  loadUserInfo: false,
                  metadata: {
                      authorization_endpoint,
                      token_endpoint,
                      issuer,
                      end_session_endpoint: logoutUrl,
                  },
              }
            : {}),
    };
    const userManager = makeUserManager(beakerDevUserManagerParams);
    const generateAuthenticator = makeAuthenticator({
        userManager: userManager,
    });

    return {
        userManager,
        generateAuthenticator,
    };
};
