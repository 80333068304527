import React from 'react';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { parseURIParams } from '../utils';
import messages from './LogoutPage.messages';

const LogoutPage = () => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const location = useLocation();
    const { classroomId } = parseURIParams(location.search.substring(1));
    return (
        <Modal
            visible
            data-test="app-modal"
            header={formatMessage(messages.modalTitle)}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="m">
                        <Button
                            iconAlign="left"
                            iconName="external"
                            href="https://aws.training/"
                        >
                            {formatMessage(messages.modalTrainingBtnLabel)}
                        </Button>
                        {classroomId && (
                            <Button
                                variant="primary"
                                onClick={() =>
                                    history.push(`/class/${classroomId}`)
                                }
                            >
                                {formatMessage(messages.modalSigninBtnLabel)}
                            </Button>
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            {formatMessage(messages.modalBody)}
        </Modal>
    );
};

export default LogoutPage;
