import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    jamCardTitle: {
        id: 'labCard.jam.cardTitle',
        defaultMessage: 'AWS Jam Event',
    },
    jamCardDescription: {
        id: 'labCard.jam.cardDescription',
        defaultMessage:
            'In this event, you are going to put your AWS skills to the test by solving various challenges',
    },
    jamUnavailableBtnText: {
        id: 'labCard.jamUnavailableBtnText',
        defaultMessage: 'Unavailable',
    },
    jamUnavailableBtnLabel: {
        id: 'labCard.jam.unavailableBtnLabel',
        defaultMessage: 'Jam Event unavailable',
    },
    jamEventStatusLabel: {
        id: 'labCard.jam.jamEventStatusLabel',
        defaultMessage: 'Event Status',
    },
    jamEventUrlLabel: {
        id: 'labCard.jam.jamEventUrlLabel',
        defaultMessage: 'AWS Jam URL',
    },
    jamStatusPending: {
        id: 'labCard.jam.jamStatusPending',
        defaultMessage: 'Pending',
    },
    jamStatusUpdating: {
        id: 'labCard.jam.jamStatusUpdating',
        defaultMessage: 'Updating',
    },
    jamStatusNotStarted: {
        id: 'labCard.jam.jamStatusNotStarted',
        defaultMessage: 'Not started',
    },
    jamStatusOngoing: {
        id: 'labCard.jam.jamStatusOngoing',
        defaultMessage: 'Ongoing',
    },
    jamStatusEnded: {
        id: 'labCard.jam.jamStatusEnded',
        defaultMessage: 'Ended',
    },
    jamStatusCancelled: {
        id: 'labCard.jam.jamStatusCancelled',
        defaultMessage: 'Cancelled',
    },
    jamStatusError: {
        id: 'labCard.jam.jamStatusError',
        defaultMessage: 'Error',
    },
});

export default messages;
