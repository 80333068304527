import moment from 'moment-timezone';
import uuid from 'uuid4';

export const isUndefined = val => val === undefined;

export const nowTime = () => Date.now();

export const numBetween = (lower, upper) =>
    Math.floor(Math.random() * (upper - lower + 1)) + lower;

export const isBeforeTimestamp = classStartTimestamp =>
    moment().isAfter(classStartTimestamp, nowTime());

export const adjustUnixTimestamp = unixTimestamp => unixTimestamp * 1000;

export const parseURIParams = uri =>
    uri.split('&').reduce((acc, set) => {
        const [key, val] = set.split('=');
        return { ...acc, [key]: val };
    }, {});

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const generateIdempotencyToken = () => uuid();

export const openTabBase = globals => url => {
    const newWindow = globals.open(url);
    newWindow.opener = null;
};

export const sleep = ms => {
    return new Promise(res => setTimeout(res, ms));
};

export const openTab = openTabBase(window);

export const getLabIdFromLabUrl = labUrl => {
    let parsed;
    try {
        parsed = new URL(labUrl);
    } catch (err) {
        console.error(`Invalid Lab URL: ${labUrl}`, err);
        return null;
    }
    const [, labId] = parsed.pathname.match(/^\/session\/(\w+)\/?/i);
    return labId;
};

/**
 * (2, "hour", "en-US") => "2 hours"
 * (30, "minutes", "es-ES") => "30 minutos"
 * @param {number} value
 * @param {string} unit
 * @param {string} locale
 * @returns string
 */
export const translateDurationUnit = (value, unit, locale) => {
    if (unit === 'min') {
        unit = 'minute';
    }
    return moment
        .duration(value, unit)
        .locale(locale)
        .humanize();
};
