import { getLabIdFromLabUrl } from '../utils/helpers';

export const transformLabsToDisplay = (loadedLabs, contentData, titleRegex) => {
    return labUrl => {
        if (!loadedLabs?.length) {
            return {
                labUrl,
                labId: getLabIdFromLabUrl(labUrl),
                position: -1,
            };
        }
        const position = loadedLabs?.findIndex(
            at => at.labUrl === labUrl || labUrl.includes(at.labId)
        );
        const { arn = '', labId } = position > -1 ? loadedLabs[position] : {};
        const title = contentData[arn]?.title?.replace(titleRegex, '');
        return {
            title,
            labUrl,
            labId: labId || getLabIdFromLabUrl(labUrl),
            arn,
            position,
        };
    };
};

export const labUrlTransformer = (
    labUrl,
    { langLocale, overrideDomain, classroomId, sessionId } = {}
) => {
    let url = new URL(labUrl);
    if (overrideDomain) {
        url = new URL(url.pathname + url.search, overrideDomain);
    }
    if (langLocale && !url.searchParams.has('locale')) {
        url.searchParams.append('locale', langLocale);
    }
    if (!url.searchParams.has('reference') && classroomId) {
        url.searchParams.append(
            'reference',
            [classroomId, sessionId].filter(el => el).join('::')
        );
    }
    return url.toString();
};
