import '@amzn/awsui-global-styles/polaris.css';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import get from 'lodash.get';
import QueryClientProvider from './QueryClientProviderWrapper';

import App from './App';
import { LOCALE_KEY } from './utils/storage-keys';
import { parseURIParams } from './utils';
import LangLocaleProvider from './LangLocaleProvider';

const initLocale =
    sessionStorage.getItem(LOCALE_KEY) ||
    get(
        parseURIParams(window.location.search.substring(1)),
        'locale',
        window.navigator.language || 'en-US'
    );

render(
    <LangLocaleProvider value={initLocale}>
        <BrowserRouter>
            <QueryClientProvider>
                <App />
            </QueryClientProvider>
        </BrowserRouter>
    </LangLocaleProvider>,
    document.getElementById('root')
);
