import React from 'react';
import { Link } from '@amzn/awsui-components-react';

export const ActiveTrainingLink = ({ labUrl, labId = "", title = "" }) => {
    return (
        <p>
            <Link external href={labUrl}>
                {title || labId}
            </Link>
        </p>
    );
};
