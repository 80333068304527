import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from 'react-query';
import _get from 'lodash/get';
import Loader from '../Loader';
import messages from './ClassPage.messages';
import ClassBanner from '../ClassBanner';
import ClassMain from '../ClassMain';
import ClassErrorModal from '../ClassErrorModal/ClassErrorModal';
import { useUser } from '../utils/user';
import { useLangLocale } from '../LangLocaleProvider';

import './ClassPage.scss';
import environment from '../.config/environment';

import { statelessPerformFetch } from '../sagas';
import { useHistory } from 'react-router-dom';
import { useUserManager } from '../.config/configureAuth';

const ClassPage = ({
    intl: { formatMessage },
    classroomId,
    globals = window,
}) => {
    const { featureFlags = { listActiveTrainings: false } } = environment(
        globals
    );
    const message = formatMessage(messages.classPageLongLoad);
    const { localeSet } = useLangLocale();
    const { idToken } = useUser();
    const history = useHistory();
    const userManager = useUserManager();
    const location = useLocation();

    const commonFetchHeaders = {
        headers: {
            Authorization: idToken,
            'Content-Type': 'application/json',
        },
    };
    const getClassroomUrl = `/GetClassroom/${classroomId}`;
    const getClassFetch = () =>
        statelessPerformFetch(getClassroomUrl, commonFetchHeaders, globals);

    const { data, isLoading, error, isSuccess } = useQuery(
        ['getClassroom', classroomId],
        getClassFetch,
        {
            enabled: !!idToken,
            onError: error => {
                const { status, type, cause } = error || {};
                if (cause === 'MissingPIIError') {
                    history.push('/profile');
                } else if (status === 401 && type === 'NotAuthorizedError') {
                    userManager.signinRedirect({
                        state: {
                            pathname: location.pathname,
                            search: location.search,
                        },
                    });
                }
            },
        }
    );

    const listActiveTrainingsEnabled =
        featureFlags.listActiveTrainings && isSuccess;
    const loadedLabs = useQuery(
        ['listActiveTrainings', classroomId],
        () =>
            listActiveTrainingsEnabled
                ? statelessPerformFetch(
                      `/ListActiveTrainings/${classroomId}`,
                      commonFetchHeaders,
                      globals
                  )
                : null,
        {
            enabled: listActiveTrainingsEnabled,
        }
    );

    const refetchLoadedLabs = () =>
        featureFlags.listActiveTrainings && loadedLabs.refetch();
    const title = _get(data, 'course.title');

    const messageAfterSecondAndAHalf = error => {
        return isLoading && message;
    };

    useEffect(() => {
        const classLangLocale = _get(data, 'classroom.langLocale');
        if (classLangLocale) {
            localeSet(classLangLocale);
        }
    }, [data, localeSet]);

    return (
        <>
            <Loader
                isLoading={isLoading}
                message={messageAfterSecondAndAHalf(error)}
            >
                {title && <ClassBanner title={title} />}
                <ClassMain
                    classData={data}
                    loadedLabs={loadedLabs.data}
                    refetchLoadedLabs={refetchLoadedLabs}
                />
                {!!(error || loadedLabs.error) ? (
                    <ClassErrorModal error={error || loadedLabs.error} />
                ) : null}
            </Loader>
        </>
    );
};

export default ClassPage;
