import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import logo from './logo.svg';
import { Button, ButtonDropdown } from '@amzn/awsui-components-react';
import messages from './ClassHeader.messages';
import './ClassHeader.scss';
import { useLocation } from 'react-router';
import { PATH_KEY, SEARCH_KEY, LOGOUT_KEY } from '../utils/storage-keys';
import { cognitoSignoutClient } from '../utils/api';

export const ClassHeader = ({
    intl: { formatMessage },
    userManager,
    globals = window,
    featureFlags,
}) => {
    const signOut = cognitoSignoutClient(globals);
    const storage = globals.sessionStorage;
    const location = useLocation();
    const path = location.pathname.match(/\/class\/(?<classroomId>\w+)/);
    const classroomId = path?.groups?.classroomId;

    const [data, dataSet] = useState({});

    const logout = async () => {
        if (classroomId) {
            storage.setItem(PATH_KEY, location.pathname);
            storage.setItem(SEARCH_KEY, location.search);
            storage.setItem(LOGOUT_KEY, classroomId);
        }
        const { refresh_token } = await userManager.getUser();
        await signOut(refresh_token);
        userManager.signoutRedirect();
    };

    const onItemClick = async event => {
        if (event.detail.id === 'signOut') {
            await logout();
        }
    };

    useEffect(() => {
        userManager.getUser().then(dataSet);
    }, [userManager, userManager.events]);

    useEffect(() => {
        userManager.events.addUserLoaded(dataSet);
    }, [userManager.events]);

    return (
        <header className="class-header">
            <img src={logo} alt={formatMessage(messages.awsTAndC)} />
            {/*https://sim.amazon.com/issues/BKR-5569*/}
            {featureFlags?.userSuppliedPII ? (
                <ButtonDropdown
                    items={[
                        {
                            text: data?.profile?.email,
                            id: 'email',
                            disabled: true,
                        },
                        {
                            text: formatMessage(messages.profilePageLabel),
                            id: 'profilePage',
                            href: '/profile',
                        },
                        {
                            text: formatMessage(messages.logoutBtnLabel),
                            id: 'signOut',
                        },
                    ]}
                    onItemClick={onItemClick}
                >
                    {formatMessage(messages.myAcoountLabel)}
                </ButtonDropdown>
            ) : (
                <Button onClick={logout}>
                    {formatMessage(messages.logoutBtnLabel)}
                </Button>
            )}
        </header>
    );
};

export default injectIntl(ClassHeader);
