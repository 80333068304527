import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';

const FIVE_MIN_IN_MS = 5 * 60 * 1000;
const DEFAULT_INTERVAL_MS = 100;

const exponentialRetryDelayWithJitter = attempt => {
    const expBackoff = Math.pow(2, attempt + 1);
    const jitter = Math.random();
    return Math.floor(DEFAULT_INTERVAL_MS * jitter * expBackoff);
};

const QueryClientProviderWrapper = ({ children }) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: 3,
                retryDelay: exponentialRetryDelayWithJitter,
                cacheTime: FIVE_MIN_IN_MS,
            },
            mutations: {
                retry: 0,
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
};

export default QueryClientProviderWrapper;
